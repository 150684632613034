
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import dayjs from "dayjs";
import {
  createRegularlyReservation,
  createReservation
} from "@/api/reservation";
import {
  sendCreateRegularyReservationBotMessage,
  sendCreateReservationBotMessage
} from "@/api/message";
import { saveErrorLog } from "@/api/error";
import {
  ReservationEditingRow,
  RegularlyReservationEditingRow
} from "@/entities/reservation";

type WeekOption = {
  id: string;
  text: string;
};

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextArea,
    MTextField,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    defaultStudentIds: Array,
    usePicker: Boolean
  }
})
export default class MsReserveModal extends Vue {
  students: Student[] = [];
  defaultStudentIds: string[] = [];
  usePicker = false;
  reservations: ReservationEditingRow[] = [];
  regularlyReservations: RegularlyReservationEditingRow[] = [];

  get weekOptions(): WeekOption[] {
    return ["日", "月", "火", "水", "木", "金", "土"].map(text => ({
      id: text,
      text: `毎週${text}曜日`
    }));
  }

  onStudentsUpdated(students: Student[]) {
    this.students = students;
  }

  getWeekdayIndex(v: string): number {
    return ["日", "月", "火", "水", "木", "金", "土"].indexOf(v);
  }

  isValidDateString(value: string): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(value);
  }

  isValidTimeString(value: string): boolean {
    return /^\d{2}:\d{2}$/.test(value);
  }

  isFuture(dateString: string, timeString?: string): boolean {
    return dayjs(
      `${dateString} ${timeString ?? dayjs().format("hh:mm")}`
    ).isAfter(dayjs());
  }

  get isValidData(): boolean {
    if (
      this.reservations.length === 0 &&
      this.regularlyReservations.length === 0
    )
      return false;
    return (
      this.reservations.every(
        ({ data }) =>
          this.isValidDateString(data.date) &&
          this.isValidTimeString(data.time) &&
          this.isFuture(data.date, data.time)
      ) &&
      this.regularlyReservations.every(
        ({ data }) =>
          data.weekday &&
          this.isValidTimeString(data.time) &&
          (!data.useDeletionTime ||
            (this.isValidDateString(data.deletionTime ?? "") &&
              this.isFuture(data.deletionTime!)))
      )
    );
  }

  addReservationRow() {
    this.reservations = [
      ...this.reservations,
      {
        data: {
          date: "",
          time: ""
        }
      }
    ];
  }

  removeReservationRow(index: number) {
    this.reservations = this.reservations.filter((_, i) => i !== index);
  }

  addRegularlyReservationRow() {
    this.regularlyReservations = [
      ...this.regularlyReservations,
      {
        data: {
          type: "week",
          time: "",
          useDeletionTime: false,
          weekday: "",
          deletionTime: ""
        }
      }
    ];
  }

  removeRegularlyReservationRow(index: number) {
    this.regularlyReservations = this.regularlyReservations.filter(
      (_, i) => i !== index
    );
  }

  get fromName(): string {
    let fromName = "";
    if (store.state.role?.data.type === "admin") {
      fromName = "みんがく管理人";
    } else if (
      store.state.role?.data.type === "tutor" &&
      store.state.tutor &&
      store.state.school
    ) {
      fromName =
        store.state.school.data.name +
        " " +
        store.state.tutor.main.data.name +
        "先生";
    }
    return fromName;
  }

  async reserve() {
    if (!this.isValidData || !store.state.role) {
      return;
    }

    store.commit("START_LOADING", "予約登録中...");

    const promises = [] as Promise<void>[];
    this.reservations.forEach(({ data }) => {
      const reserveAt = dayjs(`${data.date} ${data.time}`).locale("ja");

      this.students.forEach(student => {
        promises.push(createReservation(student.ref, reserveAt.unix()));
        promises.push(
          sendCreateReservationBotMessage(
            student.ref,
            this.fromName,
            reserveAt.format("YYYY/M/D HH:mm")
          )
        );
      });
    });
    this.regularlyReservations.forEach(({ data }) => {
      if (!data.weekday) return;
      const targetDayIndex = this.getWeekdayIndex(data.weekday);
      const [hh, mm] = data.time.split(":").map(v => +v);
      let reservedNextAt = dayjs()
        .locale("ja")
        .hour(hh)
        .minute(mm)
        .second(0)
        .day(targetDayIndex);
      if (dayjs().isAfter(reservedNextAt)) {
        // 必ず未来日になるように調整
        reservedNextAt = reservedNextAt.add(7, "day");
      }

      const deletionTime = data.useDeletionTime
        ? dayjs(data.deletionTime).unix()
        : undefined;
      this.students.forEach(student => {
        promises.push(
          createRegularlyReservation(
            student.ref,
            reservedNextAt.unix(),
            "week",
            reservedNextAt.format("HH:mm"),
            reservedNextAt.day(),
            reservedNextAt.date(),
            deletionTime
          )
        );
        promises.push(
          sendCreateRegularyReservationBotMessage(student.ref, this.fromName)
        );
      });
    });

    Promise.all(promises)
      .then(() => {
        alert("学習予定を登録しました");
        store.commit("END_LOADING");
        this.$router.go(0);
      })
      .catch(async e => {
        store.commit("END_LOADING");
        alert(`予定の登録に失敗しました\n\n${e}`);
        await saveErrorLog(
          store.state.role,
          e.code,
          e.message,
          "Failed to create reservation"
        );
      });
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.students = store.state.students.filter(student => {
      return this.defaultStudentIds.some(id => student.ref.id === id);
    });
  }
}
