
import { getCsvText } from "@/api/csv";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MTableConditionItem from "@/components/MTableConditionItem.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MTextField from "@/components/form/MTextField.vue";
import MsMultiRunDropDown from "@/components/student/MsMultiRunDropDown.vue";
import MSAddModal from "@/components/student/MSAddModal.vue";
import MsSendModal from "@/components/student/MsSendModal.vue";
import MsAddGroupModal from "@/components/student/MsAddGroupModal.vue";
import MsReserveModal from "@/components/student/MsReserveModal.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { exportToShiftJisCsv } from "@/utils/csv";
import { State } from "@/store/helper";
import { saveErrorLog } from "@/api/error";
import { qandaQuestionsDefinitions } from "@/utils/table";
import { getStudentAndQandaChatHistory } from "@/api/qanda";
import { Breadcrumb } from "../components/MHeaderPanel.vue";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MTableConditionItem,
    MNavBar,
    MHeaderPanel,
    MTextField,
    MsMultiRunDropDown,
    MSAddModal,
    MsSendModal,
    MsAddGroupModal,
    MsReserveModal
  }
})
export default class QandaQuestionList extends Vue {
  breadcrumbs: Breadcrumb[] = [
    {
      text: "生徒一覧",
      link: "/student"
    }
  ];
  students: Student[] = [];
  student?: Student | null = null;
  studentId = "";
  definitions = qandaQuestionsDefinitions;
  searchKeyword = "";
  datas: { [key: string]: string | number }[] = [];

  selectedIds: string[] = [];
  email = "";
  loading = false;

  @State("termStart", "studentList") termStart!: string;
  @State("termStartTime", "studentList") termStartTime!: number;
  @State("termEnd", "studentList") termEnd!: string;
  @State("termEndTime", "studentList") termEndTime!: number;

  get filteredDatas() {
    if (this.searchKeyword.length === 0) {
      return this.datas;
    }

    return this.datas.filter(data =>
      data?.id
        .toString()
        .toLowerCase()
        .includes(this.searchKeyword.toLowerCase())
    );
  }

  changeSelectedId(id: string) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(item => item !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  // TODO: ゆくゆく使うかもしれない関数なので置いておく
  async exportToCsv() {
    const confirmRes = confirm(
      `対象生徒数: ${
        this.selectedIds.length
      }人\n対象期間: ${this.termStart
        .split("-")
        .join("/")} ~ ${this.termEnd
        .split("-")
        .join("/")}\n\n上記の条件の学習データを出力しようとしています`
    );
    if (!confirmRes) {
      return;
    }

    store.commit("START_LOADING", "CSV出力中...");
    try {
      const csvText = await getCsvText(
        this.termStartTime,
        this.termEndTime,
        this.selectedIds
      );
      exportToShiftJisCsv(csvText, "みんがく_学習データ");
    } catch (e) {
      alert(`予期せぬエラーが発生しました\n\n${e.message}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get csv of learnings"
      );
    }

    store.commit("END_LOADING");
  }

  formatIsoDate(isoDate: string): string {
    const date = new Date(isoDate);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const zeroPad = (num: number) => num.toString().padStart(2, "0");
    return `${year}/${zeroPad(month)}/${zeroPad(day)}`;
  }

  convertDataTypeText(str: string) {
    if (str === "PROBLEM_SOLVER") {
      return "数学（Qutor）";
    } else if (str === "ENGLISH_TUTOR") {
      return "英語";
    } else if (str === "QNA") {
      return "Q & A";
    } else {
      return "不明なカテゴリー";
    }
  }

  async created() {
    this.loading = true;

    try {
      const { student, qandaChatHistory } = await getStudentAndQandaChatHistory(
        this.$route.params.studentId as string
      );
      this.student = student;
      const messageDatas = qandaChatHistory?.data;

      if (messageDatas?.length === 0) {
        this.loading = false;
        return;
      }

      this.datas =
        messageDatas
          ?.sort((a, b) => {
            if (a.type === b.type) {
              return Number(a.id) - Number(b.id);
            }
            return a.type.localeCompare(b.type);
          })
          .map(data => {
            return {
              id: data.id,
              type: this.convertDataTypeText(data.type),
              createTime: this.formatIsoDate(data.createTime),
              link: `${this.student!.ref.id}/${data.type}/${data.id}/question`
            };
          }) ?? [];
    } catch (e) {
      alert("Qandaチャット履歴の取得に失敗しました\n" + e.message);
    } finally {
      this.loading = false;
    }
  }
}
