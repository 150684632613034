import firebase from "firebase/app";

export async function getCsvText(
  start: number,
  end: number,
  ids: string[]
): Promise<string> {
  const callGetCsvOfLearning = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("get_csv_of_learnings");

  const res = await callGetCsvOfLearning({ start, end, ids });
  return res.data.csv;
}
