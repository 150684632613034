
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MsGroupFilterCard from "@/components/student/MsGroupFilterCard.vue";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";
import { GroupFilter, GroupJoin } from "@/entities/group";
import { Student } from "@/entities/student";
import { CustomProperty } from "@/entities/custom_property";
import store from "@/store";
import {
  isValidGradeFilter,
  isValidClassSchedulesFilter,
  isValidTotalDayCountFilter,
  isValidCreatedFilter,
  isValidNameFilter,
  isValidCustomPropertyFilter
} from "@/utils/group";
import { Options, Vue } from "vue-class-component";
import { registerGroup } from "@/api/group";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextField,
    MsGroupFilterCard,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    customProperties: Array,
    defaultStudentIds: Array
  }
})
export default class MsAddGroupModal extends Vue {
  customProperties: CustomProperty[] = [];
  defaultStudentIds: string[] = [];
  title = "";
  join: GroupJoin = "&&";
  filters: GroupFilter[] = [];
  selectedStudentIds: string[] = [];

  onStudentsUpdated(students: Student[]) {
    this.selectedStudentIds = students.map(s => s.ref.id);
  }

  get students(): Student[] {
    return store.state.students;
  }

  get validTitle() {
    return this.title.length > 0;
  }

  get validFilters() {
    if (this.filters.length === 0 && this.selectedStudentIds.length === 0) {
      return false;
    }

    if (this.filters.length > 0) {
      for (const filter of this.filters) {
        if (
          !isValidGradeFilter(filter) &&
          !isValidGradeFilter(filter) &&
          !isValidClassSchedulesFilter(filter) &&
          !isValidTotalDayCountFilter(filter) &&
          !isValidCreatedFilter(filter) &&
          !isValidNameFilter(filter) &&
          !isValidCustomPropertyFilter(filter, this.customProperties)
        ) {
          return false;
        }
      }
    }

    return true;
  }

  get validData() {
    return this.validTitle && this.validFilters;
  }

  async register() {
    if (!this.validData || this.selectedStudentIds.length === 0) {
      return;
    }
    const students = this.students
      .filter(student => this.selectedStudentIds.includes(student.ref.id))
      .map(student => student.ref);

    try {
      store.commit("START_LOADING", "登録中...");
      await registerGroup(this.title, [], this.join, students);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`グループの登録に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to register group"
      );
      return;
    }
    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    if (this.defaultStudentIds.length > 0) {
      this.selectedStudentIds = this.defaultStudentIds;
    }
  }
}
