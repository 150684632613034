
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import { Getter } from "@/store/helper";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MButton,
    MIcon
  },
  emits: [
    "export",
    "exportTodo",
    "exportStudents",
    "group",
    "print",
    "message",
    "addReservation",
    "setStudentsRecess",
    "cancelStudentsRecess",
    "deleteStudents",
    "createSchoolAIStudent"
  ],
  props: {
    disabled: Boolean
  }
})
export default class MTableConditionItem extends Vue {
  disabled = false;
  showModal = false;

  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isAdminOfSchool") isAdminOfSchool!: boolean;
  @Getter("isSupervisorOfSchool") isSupervisorOfSchool!: boolean;
  @Getter("isEmployeeOfSchool") isEmployeeOfSchool!: boolean;

  get canEditStudent(): boolean {
    return (
      this.isAdmin ||
      this.isAdminOfSchool ||
      this.isSupervisorOfSchool ||
      this.isEmployeeOfSchool
    );
  }

  changeShowModal() {
    if (this.disabled) {
      return;
    }
    this.showModal = !this.showModal;
  }

  print(target: "mingaku" | "schoolAI") {
    this.showModal = false;
    this.$emit("print", target);
  }

  group() {
    this.showModal = false;
    this.$emit("group");
  }

  message() {
    this.showModal = false;
    this.$emit("message");
  }

  createSchoolAIStudent() {
    this.showModal = false;
    this.$emit("createSchoolAIStudent");
  }

  addReservation() {
    this.showModal = false;
    this.$emit("addReservation");
  }

  setStudentsRecess() {
    this.showModal = false;
    this.$emit("setStudentsRecess");
  }

  cancelStudentsRecess() {
    this.showModal = false;
    this.$emit("cancelStudentsRecess");
  }

  deleteStudents() {
    this.showModal = false;
    this.$emit("deleteStudents");
  }

  exportData() {
    this.showModal = false;
    this.$emit("export");
  }

  exportStudentData() {
    this.showModal = false;
    this.$emit("exportStudents");
  }

  exportTodoData() {
    this.showModal = false;
    this.$emit("exportTodo");
  }
}
