import { CustomProperty } from "@/entities/custom_property";
import { FilterType, GroupFilter } from "@/entities/group";
import { convertToUnixtimeFromDate, getUnixtimeDayAgoFrom } from "./date";

const allowGradeFilterTypes: FilterType[] = ["=="];
const allowClassSchedulesFilterTypes: FilterType[] = ["in", "not-in"];
const allowTotalDayCountFilterTypes: FilterType[] = ["==", "<=", ">="];
const allowCreatedFilterTypes: FilterType[] = ["<=", ">="];
const allowNameFilterTypes: FilterType[] = ["==", "include"];

const allowGradeFilterValues: string[] = [
  "その他",
  "中1",
  "中2",
  "中3",
  "小1",
  "小2",
  "小3",
  "小4",
  "小5",
  "小6",
  "高1",
  "高2",
  "高3"
];
const allowClassSchedulesFilterValues: string[] = [
  "月曜",
  "火曜",
  "水曜",
  "木曜",
  "金曜",
  "土曜",
  "日曜"
];

export function isValidGradeFilter(filter: GroupFilter): boolean {
  if (filter.key !== "grade") {
    return false;
  }

  if (!allowGradeFilterTypes.includes(filter.type)) {
    return false;
  }

  if (
    typeof filter.value !== "string" ||
    !allowGradeFilterValues.includes(filter.value)
  ) {
    return false;
  }

  return true;
}

export function isValidClassSchedulesFilter(filter: GroupFilter): boolean {
  if (filter.key !== "classSchedules") {
    return false;
  }

  if (!allowClassSchedulesFilterTypes.includes(filter.type)) {
    return false;
  }

  if (
    typeof filter.value !== "string" ||
    !allowClassSchedulesFilterValues.includes(filter.value)
  ) {
    return false;
  }

  return true;
}

export function isValidTotalDayCountFilter(filter: GroupFilter): boolean {
  if (filter.key !== "totalDayCount") {
    return false;
  }

  if (!allowTotalDayCountFilterTypes.includes(filter.type)) {
    return false;
  }

  if (typeof filter.value !== "number") {
    return false;
  }

  return true;
}

export function isValidCreatedFilter(filter: GroupFilter): boolean {
  if (filter.key !== "created") {
    return false;
  }

  if (!allowCreatedFilterTypes.includes(filter.type)) {
    return false;
  }

  if (
    typeof filter.value !== "string" ||
    !/^\d{4}-\d{2}-\d{2}$/.test(filter.value)
  ) {
    return false;
  }

  return true;
}

export function isValidNameFilter(filter: GroupFilter): boolean {
  if (filter.key !== "name") {
    return false;
  }

  if (!allowNameFilterTypes.includes(filter.type)) {
    return false;
  }

  if (typeof filter.value !== "string") {
    return false;
  }

  return true;
}

export function isValidCustomPropertyFilter(
  filter: GroupFilter,
  properties: CustomProperty[]
): boolean {
  const keys = filter.key.split(".");
  if (keys.length !== 2 || keys[0] !== "custom") {
    return false;
  }

  const matchProperties = properties.filter(item => item.ref.id === keys[1]);

  if (matchProperties.length === 0) {
    return false;
  }

  if (matchProperties[0].data.type === "string") {
    if (filter.type !== "==" && filter.type !== "include") {
      return false;
    }
    if (typeof filter.value !== "string") {
      return false;
    }
  } else if (matchProperties[0].data.type === "number") {
    if (filter.type !== "==" && filter.type !== ">=" && filter.type !== "<=") {
      return false;
    }
    if (typeof filter.value !== "number") {
      return false;
    }
  } else {
    if (filter.type !== "==") {
      return false;
    }
    if (
      typeof filter.value !== "string" ||
      !matchProperties[0].data.choices.includes(filter.value)
    ) {
      return false;
    }
  }

  return true;
}

export function convertToStandardClassSchedulesFilter(
  filter: GroupFilter
): GroupFilter {
  if (!isValidClassSchedulesFilter(filter)) {
    return filter;
  }
  if (filter.type === "in") {
    return {
      key: filter.key,
      type: "include-in",
      value: filter.value
    };
  } else {
    return {
      key: filter.key,
      type: "include-not-in",
      value: filter.value
    };
  }
}

export function convertToStandardCreatedFilter(
  filter: GroupFilter
): GroupFilter {
  if (!isValidCreatedFilter(filter)) {
    return filter;
  }

  if (filter.type === ">=") {
    return {
      key: filter.key,
      type: "include-in",
      value: convertToUnixtimeFromDate(filter.value as string)
    };
  } else {
    return {
      key: filter.key,
      type: "include-not-in",
      value: getUnixtimeDayAgoFrom(
        convertToUnixtimeFromDate(filter.value as string)
      )
    };
  }
}
