export function generateRandomPassword(): string {
  const str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const num = 16;
  return Array.from(crypto.getRandomValues(new Uint8Array(num)))
    .map(n => str[n % str.length])
    .join("");
}

export function validPassword(password: string): boolean {
  return /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,64}$/.test(password);
}
